<template>
  <div class="">
    <div class="flex">
      <div class="container mx-auto">
        <div class="flex">
          <form
            v-if="!isLoading"
            class="needs-validation"
            @submit.prevent="onSubmit"
          >
            <div class="mx-4">
              <div class="mb-4 text-xl font-bold">Edit: {{ staff.name }}</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
             

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    First Name
                  </label>
                  <input
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    name="email"
                    v-model="form.first_name"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('first_name'),
                    }"
                    required
                    placeholder="Enter first name"
                  />
                </div>
              </div>


              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Last Name
                  </label>
                  <input
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    name="email"
                    v-model="form.last_name"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('last_name'),
                    }"
                    required
                    placeholder="Enter last name"
                  />
                </div>
              </div>
              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Email
                  </label>
                  <input
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    name="email"
                    v-model="form.email"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('email'),
                    }"
                    required
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Contact Phone
                  </label>
                  <input
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    name="name"
                    v-model="form.contact_phone"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('contact_phone'),
                    }"
                    required
                    placeholder="Enter contact phone"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Branch
                  </label>

                  <select
                    v-if="onChangeBranch"
                    v-model="form.branch"
                    :class="{
                      'is-invalid': form.errors.has('branch'),
                    }"
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                  >
                    <option value="" disabled selected>Select Branch</option>
                    <option
                      v-for="(branch, i) in branches"
                      :key="i"
                      :value="branch.id"
                    >
                      {{ branch.name }}
                    </option>
                  </select>

                  <div
                    v-else
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      flex
                      items-center
                      justify-between
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                  >
                    <div>{{ staff.branch }}</div>
                    <div>
                      <button
                        @click="onChangeBranch = true"
                        class="text-sm text-blue-500 hover:text-blue-400"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Jobe Title
                  </label>
                  <select
                    v-if="form.job_title != 'other'"
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    v-model="form.job_title"
                    :class="{
                      'is-invalid': form.errors.has('job_title'),
                    }"
                  >
                    <option value="branch_manager">Branch Manager</option>
                    <option value="teller">Teller</option>
                    <option value="other">Other</option>
                  </select>
                  <input
                    v-if="form.job_title == 'other'"
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    name="name"
                    v-model="form.job_title_other"
                    type="text"
                    :class="{
                      'is-invalid': form.errors.has('job_title'),
                    }"
                    required
                    placeholder="Enter job title"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-normal mb-2"
                    for="username"
                  >
                    Role
                  </label>

                  <select
                    v-if="onChangeRole"
                    v-model="form.role"
                    :class="{
                      'is-invalid': form.errors.has('role'),
                    }"
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                  >
                    <option value="" selected disabled>Select Role</option>
                    <option v-for="(role, i) in roles" :key="i" :value="role">
                      {{ role.display_name }}
                    </option>
                  </select>
                  <div
                    v-else
                    class="
                      shadow
                      appearance-none
                      border
                      rounded
                      flex
                      items-center
                      justify-between
                      w-full
                      py-2
                      px-3
                      text-gray-700
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                  >
                    <div>{{ staff.role }}</div>
                    <div>
                      <button
                        @click="onChangeRole = true"
                        class="text-sm text-blue-500 hover:text-blue-400"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
              </div>
              <!-- SUBMIT BUTTON -->
              <div class="w-full overflow-hidden xl:my-2 xl:px-3 xl:w-1/2">
                <!-- Column Content -->
                <button
                  class="
                    px-4
                    py-2
                    rounded
                    text-white
                    inline-block
                    shadow-lg
                    bg-blue-500
                    hover:bg-blue-600
                    focus:bg-blue-700
                  "
                  type="submit"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";

// import Overview from "./View/Overview";
// import Transactions from "./View/Transactions.vue";
// import Worksheet from "./View/Worksheet.vue";
// import Log from "./View/Log.vue";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  // components: { Overview, Transactions, Worksheet, Log },
  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    isLoading: false,
    staff: null,

    branches: [],
    roles: [],
    form: new Form({
      email: "",
      contact_phone: "",
      branch: "",
      job_title: "",
      job_title_other: "",
      user_id: null,
      role: "",
      first_name: "",
      last_name: "",
    }),

    current: "overview",

    onChangeRole: false,
    onChangeBranch: false,
  }),

  created() {
    var _this = this;
    _this.loadData(this.$route.params.id);
    _this.loadBranches();
    _this.loadRoles();
    if (!this.checkPermissionExist(this.user.data, "staffs-update")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    async loadData(id) {
      //Get Data

      this.isLoading = true;

      try {
        const { data } = await axios.get(`/staffs/${id}`);

        this.isLoading = false;
        this.staff = data.data;
        this.form.user_id = data.data.user_id;

        this.form.email = this.staff.email;
        this.form.contact_phone = this.staff.contact_phone;
        this.form.job_title = this.staff.job_title;
    
        const fullName = this.staff.name.trim(); 
  const lastSpaceIndex = fullName.lastIndexOf(' '); 
  let firstName, lastName;
  
  if (lastSpaceIndex !== -1) {
    // If there is a last name
    firstName = fullName.substring(0, lastSpaceIndex);
    lastName = fullName.substring(lastSpaceIndex + 1);
  } else {
    firstName = fullName;
    lastName = ''; 
  }
  
  this.form.first_name = firstName;
  this.form.last_name = lastName;
        

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branches = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async loadRoles() {
      // Submit the form

      try {
        const { data } = await axios.get(`/roles`);

        this.roles = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.patch("/staff/update");

        this.isLoading = false;
        this.$router.push({ path: "/staffs" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    // async updateStatus() {
    //   // Submit the form
    //   this.isLoading = true;

    //   try {
    //     await this.form.patch("/staff/update/status");

    //     this.isLoading = false;

    //     // this.$router.push({ path: "/staffs" });

    //     return true;
    //   } catch (e) {
    //     this.isLoading = false;

    //     console.log("Error");
    //     return false;
    //   }
    // },
  },
};
</script>
